<template>
<div>
  <div id="about" v-html="getOrganization.data.block1">
    <!-- About -->
  </div>
</div>
</template>



<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import ApiService from "@/core/services/api.service";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";
import { mapGetters } from "vuex";
//import Button from './vue-bootstrap/Button.vue';

export default {
  name: "about",
  components: {
    //OrganizationMenu
    //Button
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters([
      "getOrganization"
    ]),
    isBandoZac() {
      return window.location.host.includes('bandozac.bipart.it');
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.isBandoZac ? "La vision" : this.$t("MAINMENU.PROJECT") }]);
     //this.getOrganization();
    // console.log(this.getOrganization);
  },
  created() {

  },
  methods: {
    // getOrganization() {
    //   return new Promise((resolve, reject) => {
    //     ApiService.get(this.$apiResource, "organizations-expanded/"+ "1")
    //       .then(res => {
    //         this.organization = res.data;
    //       })
    //       .catch(() => {
    //         // .catch(({ response }) => {
    //         // context.commit(SET_ERROR, [response.data.error]);
    //         //console.log('errore');
    //         reject(0);
    //       });
    //   });
    // }
  }
}
</script>

<style></style>
